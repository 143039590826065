import * as React from "react";
import {
  Edit as AdminEdit, SimpleForm, AutocompleteInput, ReferenceInput, TextInput, DateInput,
  ArrayInput, SimpleFormIterator, NumberInput
} from 'react-admin';

export default function Edit (props) {
  const redirect = (basePath, id, data) => `/procurement_lists/${data.procurement_list_id}/show`
  return <AdminEdit {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="code" />
      <DateInput source="delivery_estimated_on" />
      <ReferenceInput source="procurement_list_id" reference="procurement_lists">
        <AutocompleteInput optionValue="id" optionText="name" />
      </ReferenceInput>
      <ArrayInput source="purchase_items">
        <SimpleFormIterator>
          <ReferenceInput source="product_id" reference="products" label="Product">
            <AutocompleteInput optionValue="id" optionText="name" />
          </ReferenceInput>
          <ReferenceInput source="space_id" reference="spaces" label="Space">
            <AutocompleteInput optionValue="id" optionText="name" />
          </ReferenceInput>
          <NumberInput source="quantity" label="Quantity" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </AdminEdit>
};
