import React from 'react'
import {
  TextInput, Edit as AdminEdit, SimpleForm, ImageInput, ImageField, ReferenceInput, AutocompleteInput,
} from 'react-admin'
import CategorySelect from '../../components/resources/product/edit/CategorySelect'
import DimensionsInput from '../../components/resources/product/edit/DimensionsInput'

export default function Edit (props) {
  return (
    <AdminEdit undoable={false} {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <CategorySelect />
        <DimensionsInput />
        <ImageInput source="photo" accept="image/png, image/jpg, image/jpeg">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="number" />
        <ReferenceInput source="brand_id" reference="brands">
          <AutocompleteInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <TextInput source="manufacturer_url" />
        <TextInput source="description" multiline rows={10} style={{width:'100%'}}/>
      </SimpleForm>
    </AdminEdit>
  )
};
