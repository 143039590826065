import * as React from "react";
import {
  Show as AdminShow, SimpleShowLayout, TextField, ReferenceManyField, Datagrid, EditButton, DeleteButton,
  ShowButton
} from 'react-admin';
import ContentAdd from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

export default function Show (props) {
  const { id } = props;

  const deleteRedirect = () => `/procurement_lists/${id}/show`

  const CreateProcurementButton = ({ record }) => (
    <Button
      component={Link}
      to={{
        pathname: '/procurements/create',
        state: { record: { procurement_list_id: record.id } },
      }}
    >
      <ContentAdd />&nbsp;Create Procurement
    </Button>
  );
  const CreateOrderButton = ({ record }) => (
    <Button
      component={Link}
      to={{
        pathname: '/orders/create',
        state: { record: { procurement_list_id: record.id } },
      }}
    >
      <ContentAdd />&nbsp;Create Order
    </Button>
  );
  return (
    <AdminShow {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <ReferenceManyField label="Procurements" reference="procurements" target="procurement_list_id">
          <Datagrid>
            <TextField sortable={false} label="Product" source="product.name" />
            <TextField sortable={false} label="Space" source="space.name" />
            <TextField sortable={false} source="quantity" />
            <TextField sortable={false} label="Outstanding" source="unfulfilled_quantity" />
            <TextField sortable={false} label="Purchased" source="purchased_quantity" />
            <TextField sortable={false} label="Transferred" source="transferred_quantity" />
            <EditButton />
            <DeleteButton redirect={deleteRedirect} />
          </Datagrid>
        </ReferenceManyField>
        <CreateProcurementButton />
        <ReferenceManyField label="Orders" reference="orders" target="procurement_list_id" link={true}>
          <Datagrid>
            <TextField source="code" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <CreateOrderButton />
      </SimpleShowLayout>
    </AdminShow>
  )
}
