import { createTheme } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'

export default createTheme({
  palette: {
    primary: {
      main: 'rgb(40, 60, 124)',
    },
    secondary: {
      main: 'rgb(209, 98, 99)',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['"Montserrat"', 'Helvetica', 'Arial', 'Arial', 'sans-serif'].join(','),
    body1: {
      fontWeight: 500
    }
  },
  shape: {
    borderRadius: 0
  }
})
