import React from 'react'
import {
  List as AdminList,
  Datagrid,
  TextField,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps
} from 'react-admin'
import Empty from '../../components/Empty'
import GenericEditButton from '../../components/GenericEditButton';

const ListActions = (props) => {
  const { className, filters, maxResults, ...rest } = props;
  const { total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton />
      <ExportButton disabled={total === 0} maxResults={maxResults} />
    </TopToolbar>
  );
};

export const List = (props) => (
  <AdminList {...props}
    actions={<ListActions />}
    empty={<Empty />}
    sort={{ field: "created_at", order: "desc" }}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="abbreviation" />
      <GenericEditButton />
    </Datagrid>
  </AdminList>
)

export default List
