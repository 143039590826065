import * as React from "react";
import { Show as AdminShow, SimpleShowLayout, TextField } from 'react-admin';

export default function Show (props) {
  return (
    <AdminShow {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
      </SimpleShowLayout>
    </AdminShow>
  )
}
