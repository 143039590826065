import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

const imgStyles = {
  maxWidth: 500
}

const RemoteImageField = (props) => {
  const { source, alt } = props;
  const record = useRecordContext(props);
  const endpoint = record[source] ? record[source] : 'https://via.placeholder.com/500x300?text=Missing+photo';
  return <img style={imgStyles} src={endpoint} alt={record[alt]} />;
}

RemoteImageField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  remoteBaseUrl: PropTypes.string,
};

export default RemoteImageField;
