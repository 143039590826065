import React from 'react'
import {
  TextInput, Edit as AdminEdit, SimpleForm
} from 'react-admin'

export default function Edit (props) {
  return (
    <AdminEdit undoable={false} {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </AdminEdit>
  )
}
