import * as React from "react";
import {
  Create as AdminCreate, SimpleForm, TextInput
} from 'react-admin';

export default function Create (props) {
  return <AdminCreate {...props}>
    <SimpleForm redirect="/procurement_lists">
      <TextInput source="name" />
    </SimpleForm>
  </AdminCreate>
};
