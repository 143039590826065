import React, { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
const LogoutButton = forwardRef((props, ref) => {

  const logout = useLogout();
  const handleClick = function(){
    logout();
    // window.location.hash="#/login";
    // window.location =  window.location;
  }
  return (
      <MenuItem
          onClick={handleClick}
          ref={ref}
      >
          <ExitIcon /> Logout
      </MenuItem>
  );
});

export default LogoutButton;
