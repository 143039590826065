export default function serializeParams (params) {
  if (params === undefined) {
    return ''
  }

  const getPairs = (obj, keys = []) =>
    Object.entries(obj).reduce((pairs, [key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        for (const element of value) {
          pairs.push([[...keys, key, []], element])
        }
      } else if (typeof value === 'object' && value !== null) {
        pairs.push(...getPairs(value, [...keys, key]))
      } else {
        pairs.push([[...keys, key], value])
      }
      return pairs
    }, [])

  const pairs = getPairs(params).map(function ([[key0, ...keysRest], value]) {
    return `${key0}${keysRest.map(a => `[${a}]`).join('')}=${value}`
  })

  return pairs.join('&')
};
