import * as React from "react";
import {
  Show as AdminShow, SimpleShowLayout, ReferenceField, TextField, DateField, ReferenceManyField,
  Datagrid
} from 'react-admin';

export default function Show (props) {
  return <AdminShow {...props}>
    <SimpleShowLayout>
      <TextField source="code" />
      <DateField source="delivery_estimated_on" />
      <ReferenceField source="procurement_list_id" reference="procurement_lists" link={true}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceManyField label="Purchase items" reference="purchase_items" target="order_id">
        <Datagrid>
          <TextField source="product.name" />
          <TextField source="space.name" />
          <TextField source="quantity" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </AdminShow>
};
