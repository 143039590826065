import env from '../env'
const authProvider = {
  login: async ({ email, password }) => {
    const request = new Request(`${env.API_URL}/users/sign_in`, {
      method: 'POST',
      body: JSON.stringify({user:{ email: email, password: password }}),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    const response = await fetch(request);
    if(response.status === 401){
      alert("Unauthorized; check your email/password");
      return Promise.reject();
    }
    if (response.status < 200 || response.status >= 300) {
      return Promise.reject();
    }
    const header = response.headers.get("Authorization");
    const token = header.split(' ')[1];
    localStorage.setItem(env.TOKEN_KEY, token)
    localStorage.setItem(env.ROLE_KEY, "user")

    return Promise.resolve();
  },
  logout: async() => {
    if(!localStorage.getItem(env.TOKEN_KEY))
    {
      return Promise.resolve()
    }

    const request = new Request(`${env.API_URL}/users/sign_out`, {
      method: 'DELETE',
      headers: new Headers({ 'Content-Type': 'application/json', "Authorization":`Bearer ${localStorage.getItem(env.TOKEN_KEY)}` })
    });
    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    localStorage.removeItem(env.TOKEN_KEY)
    localStorage.removeItem(env.ROLE_KEY)
    return Promise.resolve()
  },
  checkAuth: function () {
    if (window.location.pathname === '/login' || localStorage.getItem(env.TOKEN_KEY)) { return Promise.resolve(); }
    return Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(env.TOKEN_KEY);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const role = localStorage.getItem(env.ROLE_KEY)
    return role ? Promise.resolve(role) : Promise.resolve('guest')
  },
}
export default authProvider;
