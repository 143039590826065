import React from 'react'
import { TextField, Edit as AdminEdit, SimpleForm } from 'react-admin'

export default function Edit (props) {
  return (
    <AdminEdit undoable={false} {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="abbreviation" />
      </SimpleForm>
    </AdminEdit>
  )
}
