import { NumberInput, SelectInput } from 'react-admin'

export default function DimensionsInput () {
 return (
    <span>
      <NumberInput source="width" />
      &nbsp;
      <NumberInput source="length" />
      &nbsp;
      <NumberInput source="height" />
      &nbsp;
      <SelectInput source="dimension_units" choices={[
        { id: 'cm', name: 'cm' },
        { id: 'in', name: 'in' }
      ]} />
    </span>
  );
};
