import * as React from "react";
import {
  Create as AdminCreate, SimpleForm, AutocompleteInput, ReferenceInput, NumberInput
} from 'react-admin';

export default function Create (props) {
  const redirect = (basePath, id, data) => `/procurement_lists/${data.procurement_list_id}/show`
  return <AdminCreate {...props}>
    <SimpleForm redirect={redirect}>
      <ReferenceInput source="product_id" reference="products">
        <AutocompleteInput optionValue="id" optionText={record => record ? `${record.name} (${record.sku})` : ''} />
      </ReferenceInput>
      <ReferenceInput source="space_id" reference="spaces">
        <AutocompleteInput optionValue="id" optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="procurement_list_id" reference="procurement_lists">
        <AutocompleteInput optionValue="id" optionText="name" />
      </ReferenceInput>
      <NumberInput source="quantity" min="1" />
    </SimpleForm>
  </AdminCreate>
};
