import { SelectInput } from 'react-admin';
import { AutocompleteInput } from 'react-admin';
import { Box } from '@material-ui/core';

// TODO: abstract subcategories and subcategories
// to backend to support custom categories for multi-tenancy.
const categories = [
  'formal',
  'branding',
  'informal'
];

const subcategories = [
  "Armoire",
  "Bench",
  "Bureau",
  "Caisson",
  "Casier",
  "Fauteuil bureau",
  "Pieds",
  "Plateau",
  "Table réunion",
  "Chaise",
  "Tabouret",
  "Banc",
  "Banquette",
  "Pouf",
  "Tapis",
  "Console",
  "Repose Pieds",
  "Commode",
  "Phone Box",
  "Banc",
  "PaperBoard",
  "Etagère",
  "Table",
  "Lampe",
  "WhiteBoard",
  "Siège d'appoint",
  "Canapé",
  "Porte Manteau",
  "Chevalet",
  "Sofa",
]

export default function CategorySelect () {
  const categoryChoices = categories.map(category => {
    return { id: category, name: category }
  });
  const subcategoryChoices = subcategories.map(subcategory => {
    return { id: subcategory, name: subcategory }
  });
  return (
    <Box display="flex">
      <Box mr="0.125em">
        <SelectInput source="category" choices={categoryChoices} />
      </Box>
      <Box ml="0.125em">
        <AutocompleteInput
          source="subcategory"
          choices={subcategoryChoices}
          allowEmpty={true}
          style={{ display: 'inline', float: 'left', marginLeft: '20px' }}
        />
      </Box>
    </Box>
  )
}
