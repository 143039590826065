import React, {
  createElement,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card, Avatar, createTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import { useHistory } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';
import LoginForm from './LoginForm';

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#221E53',
      backgroundRepeat: 'repeat',
      backgroundSize: '100px 174px',
      backgroundImage: 'url("https://www.deskeo.com/wp-content/themes/nq-deskeo/assets/img/pattern_light.png")'
    },
    logo: {
      maxWidth: 300,
      marginTop: '6em',
    },
    card: {
      minWidth: 300,
      borderRadius: 0,
      marginTop: '5em',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  }),
  { name: 'RaLogin' }
);

const LoginView = props => {
  const {
    title,
    classes: classesOverride,
    className,
    children,
    notification,
    staticContext,
    backgroundImage,
    ...rest
  } = props;
  const containerRef = useRef();

  const classes = useStyles(props);
  const checkAuth = useCheckAuth();
  const history = useHistory();
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push('/');
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  let backgroundImageLoaded = false;

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  return (
    <div
      className={classnames(classes.main, className)}
      {...rest}
      ref={containerRef}
    >
      <img className={classes.logo} src="https://www.deskeo.com/wp-content/themes/nq-deskeo/assets/img/logo-deskeo-simple.png" alt="Deskeo" />
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        <LoginForm />
      </Card>
      {notification ? createElement(notification) : null}
    </div>
  );
};

const Login = props => {
  const { theme, ...rest } = props;
  const muiTheme = useMemo(() => createTheme(theme), [theme]);
  return (
    <ThemeProvider theme={muiTheme}>
      <LoginView {...rest} />
    </ThemeProvider>
  );
};

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
};

export default Login;
