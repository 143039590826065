import * as React from "react";
import { Show as AdminShow, SimpleForm, TextField, FunctionField, NumberField } from 'react-admin';

export default function Show (props) {
  return (
    <AdminShow {...props}>
      <SimpleForm>
        <TextField label="Procurement List" source="procurement_list.name" />
        <TextField label="Space" source="space.name" />
        <FunctionField label="Product" render={record => `${record.product.name} ${record.product.sku}`} />
        <NumberField source="quantity" />
      </SimpleForm>
    </AdminShow>
  )
}
