import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CreateButton, useListContext } from 'react-admin';

export default function Empty () {
  const { basePath, resource } = useListContext();
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        No {resource} available
      </Typography>
      <Typography variant="body1">
        Start creating {resource} now
      </Typography>
      <CreateButton basePath={basePath} />
    </Box>
  );
};
