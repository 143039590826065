import * as React from "react";
import {
  Show as AdminShow, TextField, ReferenceField, ReferenceArrayField, SingleFieldList, ChipField
} from 'react-admin';
import { Box } from '@material-ui/core';
import { CompactShowLayout } from 'ra-compact-ui';
import RemoteImageField from '../../components/RemoteImageField';
import CreateProcurementForm from '../../components/resources/product/show/CreateProcurementForm';

export default function Show(props) {
  return (
    <AdminShow {...props}>
      <CompactShowLayout layoutComponents={[Box]}>
        <RemoteImageField source="photo_url" alt="name" />
        <Box display="flex">
          <Box mr="0.5em">
            <TextField source="sku" />
          </Box>
          <Box mr="0.5em" ml="0.5em">
            <TextField source="name" />
          </Box>
          <Box ml="0.5em">
            <ReferenceField source="brand_id" reference="brands" link={true}>
              <TextField source="name" />
            </ReferenceField>
          </Box>
        </Box>
        <Box display="flex">
          <Box mr="0.5em">
            <TextField source="category" />
          </Box>
          <Box mr="0.5em" ml="0.5em">
            <TextField source="subcategory" />
          </Box>
          <Box ml="0.5em">
            <ReferenceArrayField label="Tags" source="label_ids" reference="labels">
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          </Box>
        </Box>
        <CreateProcurementForm />
      </CompactShowLayout>
    </AdminShow>
  )
}
