import dataProvider from './data';
import {DirectUpload} from 'activestorage';
import env from '../env';

const createBlobSignedKey = async function(photo) {
  if (!(photo && photo.rawFile instanceof File)) {
    return new Promise((resolve) => resolve([]));
  }
  const railsActiveStorageDirectUploadsUrl = `${env.API_URL}/rails/active_storage/direct_uploads`;
  return new Promise((resolve, reject) => {
    const upload = new DirectUpload(photo.rawFile, railsActiveStorageDirectUploadsUrl)
    upload.create((error, blob) => {
      if (error) {
        reject(error)
      } else {
        resolve(blob.signed_id);
      }
    })
  });
};

const ImageUploadingDataProvider = {
  ...dataProvider,

  update: async (resource, params) => {
    const photoUploadingResources = ['products'];
    const resourceSupportsPhotoUpload = photoUploadingResources.indexOf(resource) > -1;
    if (resourceSupportsPhotoUpload && params.data.photo) {
      params.data.photo = await createBlobSignedKey(params.data.photo);
    }
    return dataProvider.update(resource, params);
  },

  create: async (resource, params) => {
    const photoUploadingResources = ['products'];
    const resourceSupportsPhotoUpload = photoUploadingResources.indexOf(resource) > -1;
    if (resourceSupportsPhotoUpload && params.data.photo) {
      params.data.photo = await createBlobSignedKey(params.data.photo);
    }
    return dataProvider.create(resource, params);
  },
};

export default ImageUploadingDataProvider;
