import * as React from "react";
import { Create as AdminCreate, SimpleForm, TextInput } from 'react-admin';

export default function Create (props) {
  return <AdminCreate {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="abbreviation" />
    </SimpleForm>
  </AdminCreate>
};
