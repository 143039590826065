import * as React from "react";
import { Edit as AdminEdit, SimpleForm, ReferenceInput, AutocompleteInput, NumberInput } from 'react-admin';

export default function Edit (props) {
  return (
    <AdminEdit {...props}>
      <SimpleForm>
        <ReferenceInput source="procurement_list_id" reference="procurement_lists">
          <AutocompleteInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="space_id" reference="spaces">
          <AutocompleteInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="product_id" reference="products">
          <AutocompleteInput optionValue="id" optionText={record => record ? `${record.name} (${record.sku})` : ''} />
        </ReferenceInput>
        <NumberInput source="quantity" min="1" />
      </SimpleForm>
    </AdminEdit>
  )
}
