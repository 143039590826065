import React from "react";
import {
  useNotify, useRedirect,
  ReferenceInput, AutocompleteInput, NumberInput, useCreate
} from 'react-admin';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { CompactForm } from 'ra-compact-ui';
import { useFormState } from 'react-final-form';

const SubmitButton = ({ record }) => {
  const { values } = useFormState();
  const redirect = useRedirect();
  const [create] = useCreate();
  const notify = useNotify();
  const onClick = () => {
    create('procurements', { product_id: record.id, ...values }, {
      onSuccess: ({ data }) => {
        redirect(`/procurement_lists/${data.procurement_list_id}/show`);
        notify('Procurement created');
      },
    })
  };
  return (
    <>
      <Button onClick={onClick} color="primary" variant="contained">
        Procure
      </Button>
    </>
  )
}

export default function CreateProcurementForm (props) {
  return (
    <CompactForm toolbar={null} layoutComponents={[Box]}>
      <Box display="flex" style={{ width: 'auto' }}>
        <Box mr="0.25em">
          <NumberInput source="quantity" />
        </Box>
        <Box mr="0.25em" ml="0.25em">
          <ReferenceInput source="procurement_list_id" reference="procurement_lists">
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </Box>
        <Box mr="0.25em" ml="0.25em">
          <ReferenceInput source="space_id" reference="spaces">
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </Box>
        <Box flex={1} ml="0.25em" mt="0.5em">
          <SubmitButton record={props.record} />
        </Box>
      </Box>
    </CompactForm>
  )
}
