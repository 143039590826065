// For CI build-specific environment variables, see .env files.
// These environment variables are available client-side.
const config = {
  development: {
    API_URL: 'http://localhost:3000',
    TOKEN_KEY: 'token',
    ROLE_KEY: 'role'
  },
  staging: {
    API_URL: 'https://deskeo-catalog-stage.herokuapp.com',
    TOKEN_KEY: 'token',
    ROLE_KEY: 'role'

  },
  production: {
    API_URL: 'https://deskeo-catalog-prod.herokuapp.com',
    TOKEN_KEY: 'token',
    ROLE_KEY: 'role'
  },
}

const environment = process.env.REACT_APP_ENV || process.env.NODE_ENV

export default config[environment]
